import { createSlice } from '@reduxjs/toolkit';
import { createRule } from '../../util/create-rule';
import { validateConfig } from '../../util/validate-config';

export const initialState = {
  config: {
    rules: [],
    strategy: 'bam',
    proximity: false,
    zones: {
      zone_size: 1,
      zone_factors: {
        0: 0.01,
        1: 0.01,
        2: 0.01,
        3: 0.01,
        4: 0.01,
        5: 0.01,
        6: 0.01,
        7: 0.01,
        8: 0.01,
        9: 0.01,
        10: 0.01,
        11: 0.01,
        12: 0.01,
        13: 0.01,
        14: 0.01,
      },
    },
    useAutoZones: false,
  },
  nextRuleId: 1,
  error: null,
};

const configurationSlice = createSlice({
  name: 'configuration',
  initialState,
  reducers: {
    ruleAdded(state, action) {
      const rule = action.payload;
      state.error = null;
      state.config = {
        ...state.config,
        rules: [rule, ...state.config.rules],
      };
      state.nextRuleId = state.nextRuleId + 1;
    },
    deleteRule(state, action) {
      const id = action.payload;
      state.error = null;
      state.config = {
        ...state.config,
        rules: state.config.rules.filter(rule => rule.id !== id),
      };
    },
    configSet(state, action) {
      const { config, maxId } = action.payload;
      state.error = null;
      state.config = config;
      state.maxId = maxId + 1;
    },
    error(state, action) {
      state.error = action.payload;
    },
    changeStrategy(state, action) {
      state.config.strategy = action.payload;
    },
    proximityToggled(state, action) {
      state.config.proximity = action.payload.proximity;
    },
    autoZonesToggled(state, action) {
      state.config.useAutoZones = action.payload.useAutoZones;
    },
    updateZones(state, action) {
      state.config.zones.zone_factors = action.payload;
    },
    closeNotification(state) {
      state.error = null;
    },
  },
});

const { ruleAdded, configSet, proximityToggled, autoZonesToggled } = configurationSlice.actions;

export const {
  deleteRule,
  error,
  changeStrategy,
  updateZones,
  closeNotification,
} = configurationSlice.actions;

export function addRule({ startAge, endAge, gender, restriction }) {
  return (dispatch, getState) => {
    const id = getState().configuration.nextRuleId;
    const rule = createRule(id, startAge, endAge, gender, restriction);

    dispatch(ruleAdded(rule));
  };
}

export function setConfig(json) {
  return dispatch => {
    try {
      const { config, maxId } = validateConfig(json);
      dispatch(configSet({ config, maxId }));
    } catch (e) {
      return dispatch(error(e.message));
    }
  };
}

export function toggleProximity(value) {
  return dispatch => {
    if (value === false) {
      dispatch(autoZonesToggled({ useAutoZones: Boolean(value) }));
    }
    dispatch(proximityToggled({ proximity: Boolean(value) }));
  };
}

export function toggleAutoZones(value) {
  return dispatch => {
    if (value === true) {
      dispatch(proximityToggled({ proximity: Boolean(value) }));
    }
    dispatch(autoZonesToggled({ useAutoZones: value }));
  };
}

export default configurationSlice.reducer;
