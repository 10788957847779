import { combineReducers } from 'redux';
import configuration, { initialState as configState } from '../slices/configuration';
import edge, { initialState as edgeState } from '../slices/edge';
import ui, { initialState as uiState } from '../slices/ui';

export default combineReducers({
  configuration,
  edge,
  ui,
});

export const initialState = {
  configuration: configState,
  edge: edgeState,
  ui: uiState,
};
