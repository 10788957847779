import { connect } from 'react-redux';

import {
  addRule,
  deleteRule,
  setConfig,
  error,
  changeStrategy,
  toggleAutoZones,
  toggleProximity,
  closeNotification,
  updateZones,
} from './slices/configuration';
import { connectWs, sendConfiguration, pickRecommendation } from './slices/edge';
import { openModal, closeModal } from './slices/ui';
import { App } from '../';

const mapStateToProps = state => ({
  ui: state.ui,
  config: state.configuration.config,
  strategy: state.configuration.strategy,
  error: state.configuration.error,
  ws: state.edge.ws,
  poiConfig: state.edge.configuration,
  selectedRule: state.edge.selectedRule,
  stoppedRules: state.edge.stoppedRules,
});

const mapDispatchToProps = dispatch => ({
  addRule: config => dispatch(addRule(config)),
  deleteRule: id => dispatch(deleteRule(id)),
  setConfig: config => dispatch(setConfig(config)),
  sendConfiguration: () => dispatch(sendConfiguration()),
  changeStrategy: strategy => dispatch(changeStrategy(strategy)),
  toggleProximity: value => dispatch(toggleProximity(value)),
  toggleAutoZones: value => dispatch(toggleAutoZones(value)),
  updateZones: value => dispatch(updateZones(value)),
  connectWs: () => dispatch(connectWs()),
  pickRecommendation: payload => dispatch(pickRecommendation(payload)),
  closeNotification: () => dispatch(closeNotification()),
  onError: message => dispatch(error(message)),
  openModal: component => dispatch(openModal({ component })),
  closeModal: () => dispatch(closeModal()),
});

export const AppContainer = connect(mapStateToProps, mapDispatchToProps)(App);
