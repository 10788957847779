import React, { Suspense, useEffect } from 'react';
import { Configuration } from './components/Configuration';

import './styles/avalanche.scss';
import './styles/index.scss';

const Modal = React.lazy(() => import('./components/Modal'));
const Board = React.lazy(() => import('./components/Board'));
const Notification = React.lazy(() => import('./components/Notification'));

export const App = props => {
  const { ws, onError, ui, closeModal } = props;

  useEffect(() => {
    if (ws) {
      ws.requestCurrentDetections();
      ws.requestContentViews();

      const errorsSubscription = ws.getErrorMessagesObservable().subscribe(err => {
        onError(err);
      });

      return () => errorsSubscription.unsubscribe();
    }
  }, [ws, onError]);

  return (
    <>
      <div className="wrap">
        <Configuration
          addRule={props.addRule}
          config={props.config}
          deleteRule={props.deleteRule}
          setConfig={props.setConfig}
          toggleProximity={props.toggleProximity}
          toggleAutoZones={props.toggleAutoZones}
          updateZones={props.updateZones}
          changeStrategy={props.changeStrategy}
          sendConfiguration={props.sendConfiguration}
        />
        <Suspense fallback={'Loading...'}>
          <Board
            ws={ws}
            openModal={props.openModal}
            poiConfig={props.poiConfig}
            selectedRule={props.selectedRule}
            stoppedRules={props.stoppedRules}
            pickRecommendation={props.pickRecommendation}
          />
        </Suspense>
      </div>
      <Suspense fallback={null}>
        <Notification error={props.error} closeNotification={props.closeNotification} />
      </Suspense>
      <Suspense fallback={null}>
        <Modal
          show={ui.modal !== null}
          closeCallback={closeModal}
          customClass="custom_modal_class"
          children={ui.modal}
        />
      </Suspense>
    </>
  );
};
