import { Subject, BehaviorSubject } from 'rxjs';
import { filter, map, scan } from 'rxjs/operators';

export class WsConnection {
  constructor() {
    this.jsonMessagesSubject = new Subject();
    this.decisionSubject = new BehaviorSubject(null);

    const host = process.env.REACT_APP_EDGE_ENGINE_HOST || window.location.hostname || 'localhost';
    const protocol = process.env.REACT_APP_EDGE_ENGINE_PROTOCOL || 'ws';
    const port = process.env.REACT_APP_EDGE_ENGINE_PORT || '3001';
    this.uri = `${protocol}://${host}:${port}`;
  }

  connect() {
    const p = new Promise((resolve, reject) => {
      this.websocket = new WebSocket(this.uri);
      this.websocket.onopen = () => {
        resolve();
        this.onOpen();
      };
      this.websocket.onmessage = this.onMessage.bind(this);
      this.websocket.onerror = () => reject(new Error('Connection to the Edge Engine failed.'));
    });

    return p;
  }

  getErrorMessagesObservable() {
    return this.jsonMessagesSubject.pipe(
      filter(msg => msg.type === 'error'),
      map(msg => msg.message)
    );
  }

  getDecisionObservable() {
    return this.decisionSubject.asObservable().pipe(filter(d => Boolean(d)));
  }

  getContentViewsObservable() {
    return this.jsonMessagesSubject.pipe(
      filter(msg => msg.type === 'record' && msg.data.record_type === 'content_view'),
      map(msg => msg.data.data)
    );
  }

  getLiveDetections() {
    return this.jsonMessagesSubject.pipe(
      filter(msg => msg.type === 'record'),
      filter(msg => msg.data.record_type === 'profiles'),
      scan((acc, msg) => {
        const profileSnapshot = msg.data.data;

        if (profileSnapshot && profileSnapshot.profiles) {
          return profileSnapshot.profiles.sort((a, b) => {
            if (a.gender > b.gender) return -1;
            if (a.gender < b.gender) return 1;

            if (a.age > b.age) return 1;
            if (a.age < b.age) return -1;

            return 0;
          });
        }
        return acc;
      }, [])
    );
  }

  startRule(id, relevantPersons, triggerGroup) {
    this.send('start_rule', { id, relevantPersons, triggerGroup, fetchMappings: false });
  }

  endRule(id, relevantPersons, triggerGroup) {
    this.send('end_rule', { id, relevantPersons, triggerGroup, fetchMappings: false });
  }

  requestContentViews() {
    this.send('request_content_views');
  }

  requestCurrentDetections() {
    this.send('request_profiles');
  }

  registerConfiguration(config) {
    this.send('register_configuration', { ...config, fetchMappings: false });
  }

  onOpen() {
    console.info(`Connected to Edge Engine on "${this.uri}"`);

    if (this.decisionsSubscription) {
      this.decisionsSubscription.unsubscribe();
    }
    this.decisionsSubscription = this.jsonMessagesSubject
      .pipe(
        filter(msg => msg.type === 'recommendation'),
        map(msg => msg.data)
      )
      .subscribe(decision => this.decisionSubject.next(decision));
  }

  onMessage(e) {
    if (typeof e.data === 'string') {
      this.onMessageString(e.data);
    }
  }

  send(type, data = {}) {
    const msg = JSON.stringify({
      ...data,
      type,
    });
    this.websocket.send(msg);
  }

  onMessageString(data) {
    try {
      const json = JSON.parse(data);
      if (json) {
        this.jsonMessagesSubject.next(json);
      }
    } catch (e) {
      console.warn(e);
    }
  }
}
