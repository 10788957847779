import React from 'react';

export const Strategy = props => {
  const { strategy, changeStrategy } = props;

  const handleChangeStrategy = e => changeStrategy(e.target.value);

  return (
    <select className="select" value={strategy} onChange={handleChangeStrategy} id="strategy">
      <option value="bam">Balanced Audience Matching</option>
      <option value="lam">Largest Audience Matching</option>
    </select>
  );
};
