export default {
  type: 'object',
  additionalProperties: false,
  definitions: {
    zones: {
      title: 'Zones',
      description: 'Priority zones for the proximity factor',
      properties: {
        zone_size: {
          type: 'number',
        },
        zone_factors: {
          patternProperties: {
            '^([01]?[0-9]|20)$': { type: 'number' },
          },
          additionalProperties: false,
        },
      },
      additionalProperties: false,
      required: ['zone_size', 'zone_factors'],
    },
    rule: {
      type: 'object',
      description: `A rule for the recommendation engine that will define a
 set of triggers to be analysed in real time`,
      properties: {
        id: {
          type: 'string',
        },
        trigger_group: {
          $ref: '#/definitions/trigger',
        },
      },
      required: ['id', 'trigger_group'],
      additionalProperties: false,
    },
    trigger: {
      anyOf: [
        {
          type: 'object',
          additionalProperties: false,
          properties: {},
        },
        {
          $ref: '#/definitions/age',
        },
        {
          $ref: '#/definitions/gender',
        },
        {
          $ref: '#/definitions/persons',
        },
        {
          $ref: '#/definitions/untargeted',
        },
        {
          $ref: '#/definitions/nottargeted',
        },
        {
          $ref: '#/definitions/location',
        },
        {
          $ref: '#/definitions/group_or',
        },
        {
          $ref: '#/definitions/group_and',
        },
      ],
    },
    group_or: {
      type: 'object',
      description: 'OR connector that will be applied to all the children',
      additionalProperties: false,
      title: 'OR CONNECTOR',
      properties: {
        id: {
          type: 'string',
          description: 'ID of the triggergroup. Used as foreign key by contents',
        },
        operator: {
          type: 'string',
          enum: ['OR'],
        },
        children: {
          type: 'array',
          items: {
            $ref: '#/definitions/trigger',
            description: 'Nested childrens following the trigger definition',
          },
        },
      },
      required: ['operator', 'children'],
    },
    group_and: {
      type: 'object',
      description: 'AND connector that will be applied to all the children',
      additionalProperties: false,
      title: 'AND CONNECTOR',
      properties: {
        operator: {
          type: 'string',
          enum: ['AND'],
        },
        children: {
          type: 'array',
          items: {
            $ref: '#/definitions/trigger',
            description: 'Nested childrens following the trigger definition',
          },
        },
      },
      required: ['operator', 'children'],
    },
    age: {
      description: `Age trigger. Only operator allowed is 'IS_BETWEEN'.
 Value should follow the defined pattern`,
      title: 'AGE',
      allOf: [
        {
          type: 'object',
          properties: {
            operator: {
              enum: ['IS_BETWEEN'],
            },
            value: {
              type: 'string',
              example: 1530,
              pattern: '^(([1-9][0-9]+)|[0-9]),(([1-9][0-9]+)|[1-9])$',
            },
            type: {
              enum: ['AGE'],
            },
          },
          additionalProperties: false,
          required: ['value', 'operator', 'type'],
        },
        {
          description: 'Set of age conditions',
        },
      ],
    },
    gender: {
      type: 'object',
      additionalProperties: false,
      description: `Gender trigger. Only operator allowed is 'IS'. Definition of operator optional`,
      title: 'GENDER',
      properties: {
        type: {
          type: 'string',
          enum: ['GENDER'],
        },
        value: {
          type: 'string',
          enum: ['MALE', 'FEMALE'],
        },
        operator: {
          type: 'string',
          enum: ['IS'],
        },
      },
      required: ['type', 'value'],
    },
    persons: {
      type: 'object',
      additionalProperties: false,
      description: 'Number of persons trigger',
      title: 'PERSONS',
      properties: {
        type: {
          type: 'string',
          enum: ['PERSONS'],
        },
        value: {
          type: 'string',
        },
        operator: {
          type: 'string',
          enum: ['IS', 'IS_MORE_THAN'],
        },
      },
      required: ['type', 'operator', 'value'],
    },
    untargeted: {
      type: 'object',
      additionalProperties: false,
      description: 'Used to attach the untargeted trigger',
      properties: {
        type: {
          type: 'string',
          enum: ['UNTARGETED', 'NOBODYNEARBY'],
        },
        value: {
          type: 'boolean',
        },
        operator: {
          type: 'string',
          enum: ['IS'],
        },
      },
      required: ['type', 'operator', 'value'],
    },
    nottargeted: {
      type: 'object',
      additionalProperties: false,
      description: 'Used to attach the NOT targeted trigger',
      properties: {
        type: {
          type: 'string',
          enum: ['NOTTARGETED'],
        },
        value: {
          type: 'boolean',
        },
        operator: {
          type: 'string',
          enum: ['IS'],
        },
      },
      required: ['type', 'operator', 'value'],
    },
    location: {
      type: 'object',
      additionalProperties: false,
      description: 'Location based trigger',
      title: 'LOCATION',
      properties: {
        type: {
          type: 'string',
          enum: ['LOCATION'],
        },
        value: {
          type: 'string',
        },
        operator: {
          type: 'string',
          enum: ['IS', 'IS_NOT'],
        },
      },
      required: ['type', 'operator', 'value'],
    },
    conditions: {
      in_between: {
        type: 'object',
        properties: {
          operator: {
            type: 'string',
            enum: ['IN_BETWEEN'],
          },
          value: {
            type: 'string',
          },
        },
        required: ['operator', 'value'],
      },
    },
    person: {
      type: 'object',
      additionalProperties: false,
      description: 'Person recognised that can be treated with a set of specific actions.',
      title: 'person',
      properties: {
        name: {
          type: 'string',
          description: 'text name of the person',
          example: 'John Doe',
        },
        person_id: {
          type: 'string',
          description: 'Id of the person',
          example: '15377doe',
        },
        action: {
          type: 'string',
          description: 'define what is the action to execute when this person is recognised',
          enum: ['TARGET', 'EXCLUDE'],
        },
      },
      required: ['name', 'person_id', 'action'],
    },
  },
  properties: {
    proximity: {
      type: 'boolean',
    },
    useAutoZones: {
      type: 'boolean',
    },
    zones: {
      $ref: '#/definitions/zones',
    },
    strategy: {
      type: 'string',
      enum: ['bam', 'lam'],
    },
    rules: {
      type: 'array',
      items: {
        $ref: '#/definitions/rule',
      },
    },
  },
  required: ['rules', 'strategy'],
};
