import { createSlice } from '@reduxjs/toolkit';
import { takeUntil, reduce } from 'rxjs/operators';
import { timer } from 'rxjs';
import { WsConnection } from '../../util/ws-connection';
import { error } from './configuration';

export const initialState = {
  ws: null,
  configuration: {
    rules: [],
  },
  selectedRule: null,
  stoppedRules: [],
};

const edgeSlice = createSlice({
  name: 'edge',
  initialState,
  reducers: {
    wsConnected(state, action) {
      const { ws } = action.payload;
      state.ws = ws;
    },
    configurationRegistered(state, action) {
      const { configuration } = action.payload;
      state.configuration = configuration;
    },
    recommendationPicked(state, action) {
      const stoppedRules = [...state.stoppedRules];
      if (state.selectedRule) {
        stoppedRules.unshift({
          ...state.selectedRule,
          relevantPersons: action.payload.relevantPersons,
          contentViews: action.payload.contentViews,
        });
      }
      state.stoppedRules = stoppedRules;
      state.selectedRule = action.payload.rule;
    },
  },
});

const { wsConnected, configurationRegistered, recommendationPicked } = edgeSlice.actions;

export function connectWs() {
  return async dispatch => {
    const ws = new WsConnection();
    try {
      await ws.connect();
      ws.websocket.onerror = e => dispatch(error(e.message));
      ws.websocket.onclose = () => dispatch(error('Connection to Edge Engine has been closed.'));
      dispatch(wsConnected({ ws }));
    } catch (e) {
      dispatch(error(e.message));
    }
  };
}

export function sendConfiguration() {
  return (dispatch, getState) => {
    const { ws } = getState().edge;
    if (!ws) {
      dispatch(error('Websocket connection is not opened.'));
      return;
    }

    const { config } = getState().configuration;

    ws.registerConfiguration(config);

    dispatch(configurationRegistered({ configuration: { ...config } }));
  };
}

function getContentViewsObservable(ws) {
  return ws.getContentViewsObservable().pipe(
    takeUntil(timer(500)),
    reduce((views, view) => views.concat(view), [])
  );
}

export function pickRecommendation({ rule, relevantPersons }) {
  return (dispatch, getState) => {
    const { ws, selectedRule } = getState().edge;

    if (!ws) {
      dispatch(error('Websocket connection is not opened.'));
      return;
    }

    if (selectedRule) {
      ws.endRule(selectedRule.id, selectedRule.relevantPersons, selectedRule.trigger_group);

      getContentViewsObservable(ws).subscribe(contentViews => {
        ws.startRule(rule.id);
        dispatch(recommendationPicked({ rule, contentViews }));
      });
    } else {
      ws.startRule(rule.id, relevantPersons, rule.trZigger_group);
      dispatch(recommendationPicked({ rule, relevantPersons, contentViews: null }));
    }
  };
}

export default edgeSlice.reducer;
