import React, { useState } from 'react';
import { Rules } from './Rules';
import { Tabs } from './Tabs';

export const List = props => {
  const { config, deleteRule, setConfig, name } = props;

  const [viewMode, setViewMode] = useState('left');

  return (
    <div className="6/12--desk grid__cell">
      <div className="grid grid--flush grid--middle">
        <div className="6/12 grid__cell">
          <h2>{name}</h2>
        </div>
      </div>
      <Tabs initialValue={viewMode} leftLabel="Visual" rightLabel="JSON" onToggle={setViewMode} />
      <Rules viewMode={viewMode} config={config} deleteRule={deleteRule} setConfig={setConfig} />
    </div>
  );
};
