import React, { memo } from 'react';

const Low = {
  value: 0.01,
  code: 'L',
  class: 'zone-low',
};

const Attention = {
  value: 1,
  code: 'A',
  class: 'zone-attention',
};

const Sweet = {
  value: 100,
  code: 'S',
  class: 'zone-sweet',
};

const zonesDefinitions = {
  0.01: Low,
  1: Attention,
  100: Sweet,
};

const nextZone = value => {
  switch (value) {
    case Low.value:
      return Attention.value;
    case Attention.value:
      return Sweet.value;
    default:
      return Low.value;
  }
};

const Zone = ({ index, value, disabled, onToggleZone }) => {
  const zone = zonesDefinitions[value];
  return (
    <button
      onClick={onToggleZone}
      disabled={disabled}
      className={`zones-toggling--button ${zone.class}`}
      id={`zone-${index}`}
      title={`${index}-${index + 1} meters`}
    >
      {zone.code}
    </button>
  );
};

export const Zones = memo(props => {
  const { disabled, zones, updateZones } = props;
  const zonesList = Object.values(zones);

  const toggleZone = (index, value) => {
    updateZones({ ...zones, [index]: nextZone(value) });
  };

  return (
    <div className="zones-toggling">
      <div className="zones-toggling-buttons">
        {zonesList.map((value, index) => (
          <Zone
            key={index}
            disabled={disabled}
            index={index}
            value={value}
            onToggleZone={() => toggleZone(index, value)}
          />
        ))}
      </div>
      <legend>
        <span>
          <strong>A</strong>&nbsp;= Attention,&nbsp;
        </span>
        <span>
          <strong>S</strong>&nbsp;= Sweet,&nbsp;
        </span>
        <span>
          <strong>L</strong>&nbsp;= Low
        </span>
      </legend>
    </div>
  );
});
