import React, { useState } from 'react';

export const Tabs = props => {
  const { leftLabel, rightLabel, onToggle, initialValue } = props;

  const [name] = useState(Math.random().toString(36).substring(7));
  const [viewMode, setViewMode] = useState(initialValue || 'left');
  const handleChangeViewMode = e => {
    setViewMode(e.target.value);
    onToggle(e.target.value);
  };

  const radioName = name.split(' ').join('');

  return (
    <div className="tab-frame">
      <input
        type="radio"
        value="left"
        checked={viewMode === 'left'}
        onChange={handleChangeViewMode}
        name={name}
        id={radioName + '-leftTab'}
      />
      <label className={viewMode === 'left' ? 'checked' : ''} htmlFor={radioName + '-leftTab'}>
        {leftLabel}
      </label>
      <input
        type="radio"
        value="right"
        checked={viewMode === 'right'}
        onChange={handleChangeViewMode}
        name={name}
        id={radioName + '-rightTab'}
      />
      <label className={viewMode === 'right' ? 'checked' : ''} htmlFor={radioName + '-rightTab'}>
        {rightLabel}
      </label>
    </div>
  );
};
