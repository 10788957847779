import { createSlice } from '@reduxjs/toolkit';

export const initialState = { modal: null };

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    openModal(state, action) {
      const { component } = action.payload;
      state.modal = component;
    },
    closeModal(state) {
      state.modal = null;
    },
  },
});

export const { openModal, closeModal } = uiSlice.actions;

export default uiSlice.reducer;
