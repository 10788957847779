import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

import rootReducer, { initialState } from './app/redux/reducers';
import { AppContainer } from './app/redux/container';
import { connectWs } from './app/redux/slices/edge';

const store = configureStore({
  reducer: rootReducer,
  devtools: process.env.NODE_ENV === 'development',
  preloadedState: initialState,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActionPaths: ['payload.ws', 'payload.component'],
      ignoredPaths: ['edge.ws', 'ui.modal'],
    },
    immutableCheck: {
      ignore: ['edge.ws', 'ui.modal'],
    },
  }),
});

store.dispatch(connectWs());
render(
  <Provider store={store}>
    <AppContainer />
  </Provider>,
  document.getElementById('root')
);
