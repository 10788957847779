import React, { memo } from 'react';
import { Form } from './Form';
import { List } from '../List';
import { AdvancedSettings } from './AdvancedSettings';

export const Configuration = memo(props => (
  <div className="grid">
    <div className="grid__cell">
      <h1>ARE UI</h1>
    </div>
    <Form addRule={props.addRule} />
    <List
      name="Rules"
      config={props.config}
      deleteRule={props.deleteRule}
      setConfig={props.setConfig}
    />
    <AdvancedSettings
      config={props.config}
      toggleProximity={props.toggleProximity}
      toggleAutoZones={props.toggleAutoZones}
      changeStrategy={props.changeStrategy}
      updateZones={props.updateZones}
    />
    <div className="grid__cell has__text__center">
      <button onClick={props.sendConfiguration}>Update</button>
    </div>
  </div>
));
