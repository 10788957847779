export function formatRule(rule) {
  let result = '';
  if (rule.trigger_group.type !== 'UNTARGETED' && rule.trigger_group.children) {
    const genderCondition = rule.trigger_group.children.find(child => child.type === 'GENDER');
    if (genderCondition) {
      result += ` ${genderCondition.value.toLowerCase()}`;
    }

    const ageCondition = rule.trigger_group.children.find(child => child.type === 'AGE');
    if (ageCondition) {
      result += ` ${ageCondition.value.split(',').join('-')}`;
    }

    const groupCondition = rule.trigger_group.children.find(child => child.type === 'PERSONS');
    if (groupCondition) {
      result += ` (${groupCondition.operator === 'IS' ? 'individual' : 'group'})`;
    }
  }

  if (!result) {
    result = 'Nobody';
  }

  return capitalize(result);
}

export function capitalize(sentence) {
  const words = sentence.split(' ');
  const result = [];

  words.forEach(word => {
    if (word.length) {
      result.push(word[0].toUpperCase() + word.substr(1));
    }
  });

  return result.join(' ');
}
