import React from 'react';
import { Strategy } from './Strategy';
import { Zones } from './Zones';

export const AdvancedSettings = props => {
  const { config, toggleProximity, toggleAutoZones } = props;

  return (
    <div className="advanced-settings wrap-collabsible">
      <input id="collapsible-checkbox" className="toggle" type="checkbox" />
      <label htmlFor="collapsible-checkbox" className="lbl-toggle">
        Advanced Settings
      </label>
      <div className="collapsible-content">
        <div className="grid content-inner">
          <div className="grid engine-settings">
            <div id="engine-settings--left" className="grid__cell 6/12--desk">
              <div className="grid">
                <div className="form-field proximity-factor grid__cell 4/12--desk">
                  <input
                    checked={config.proximity}
                    onChange={e => toggleProximity(e.target.checked)}
                    type="checkbox"
                    name="proximity-factor"
                    id="proximity-factor"
                  />
                  <label htmlFor="proximity-factor">Proximity Factor</label>
                </div>
                {process.env.REACT_APP_CLOUD_ARE_UI_BUILD ? null : (
                  <div className="form-field auto-zones grid__cell 4/12--desk">
                    <input
                      checked={config.useAutoZones}
                      onChange={e => toggleAutoZones(e.target.checked)}
                      type="checkbox"
                      name="auto-zones"
                      id="auto-zones"
                    />
                    <label htmlFor="auto-zones">Auto Zones</label>
                  </div>
                )}
              </div>

              <Zones
                disabled={config.useAutoZones || !config.proximity}
                updateZones={props.updateZones}
                zones={config.zones.zone_factors}
              />
            </div>

            <div id="engine-settings--right" className="grid__cell 6/12--desk">
              <div className="form-field decision-strategy">
                <label htmlFor="decision-strategy-select">Decision Strategy</label>
                <Strategy strategy={config.strategy} changeStrategy={props.changeStrategy} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
