import React, { memo, useState } from 'react';

export const Form = memo(props => {
  const [startAge, setStartAge] = useState(0);
  const [endAge, setEndAge] = useState(100);
  const [gender, setGender] = useState('all');
  const [restriction, setRestriction] = useState(null);

  const handleChangeStartAge = e => {
    const age = e.target.value;
    if (age < endAge) {
      setStartAge(Number(age));
    }
  };
  const handleChangeEndAge = e => {
    const age = e.target.value;
    if (age > startAge) {
      setEndAge(Number(age));
    }
  };

  const handleChangeGender = e => {
    const gender = e.target.value;
    if (gender === 'nobody') {
      setRestriction(null);
    }
    setGender(gender);
  };

  const handleChangeRestriction = e => {
    if (restriction === e.target.value) {
      setRestriction(null);
    } else {
      setRestriction(e.target.value);
    }
  };
  const handleSubmit = e => {
    e.preventDefault();
    props.addRule({
      startAge,
      endAge,
      gender,
      restriction,
    });
  };

  return (
    <div className="6/12--desk grid__cell">
      <h2>Create Rule</h2>
      <form onSubmit={handleSubmit}>
        <div className="columns is-multiline">
          <div className="column is-two-thirds">
            <label htmlFor="targetGroupAge" className="bold">
              Age
            </label>
            <div className="input-range">
              <input
                type="number"
                min="0"
                max="100"
                value={startAge}
                name="targetGroupStartAge"
                onChange={handleChangeStartAge}
              />
              -
              <input
                type="number"
                min="0"
                max="100"
                value={endAge}
                name="targetGroupEndAge"
                onChange={handleChangeEndAge}
              />
            </div>
            <div className="multi-range">
              <input
                type="range"
                min="0"
                max="100"
                name="targetGroupStartAge"
                value={startAge}
                onChange={handleChangeStartAge}
                required
              />
              <input
                type="range"
                min="0"
                max="100"
                name="targetGroupEndAge"
                value={endAge}
                required
                onChange={handleChangeEndAge}
              />
            </div>
          </div>
        </div>
        <div className="columns">
          <div className="grid grid--middle">
            <div className="2/12 grid__cell">
              <label className="bold">Gender</label>
            </div>
            <div className="10/12 grid__cell">
              <div className="field">
                <div className="control">
                  <input
                    id="targetGroupAll"
                    name="gender"
                    type="radio"
                    value="all"
                    onChange={handleChangeGender}
                    checked={gender === 'all'}
                  />
                  <i className="checkPseudo" />
                  <label htmlFor="targetGroupAll">
                    <span>All</span>
                  </label>
                </div>
              </div>
              <div className="field">
                <div className="control">
                  <input
                    id="targetGroupNobody"
                    name="gender"
                    type="radio"
                    value="nobody"
                    onChange={handleChangeGender}
                    checked={gender === 'nobody'}
                  />
                  <i className="checkPseudo" />
                  <label htmlFor="targetGroupNobody">
                    <span>Nobody</span>
                  </label>
                </div>
              </div>
              <div className="field">
                <div className="control">
                  <input
                    id="targetGroupMale"
                    name="gender"
                    type="radio"
                    value="male"
                    onChange={handleChangeGender}
                    checked={gender === 'male'}
                  />
                  <i className="checkPseudo" />
                  <label htmlFor="targetGroupMale">
                    <span>Male</span>
                  </label>
                </div>
              </div>
              <div className="field">
                <div className="control">
                  <input
                    id="targetGroupFemale"
                    name="gender"
                    type="radio"
                    value="female"
                    onChange={handleChangeGender}
                    checked={gender === 'female'}
                  />
                  <i className="checkPseudo" />
                  <label htmlFor="targetGroupFemale">
                    <span>Female</span>
                  </label>
                </div>
              </div>
            </div>

            {gender !== 'nobody' ? (
              <>
                <div className="2/12 grid__cell">
                  <label className="bold">Restriction</label>
                </div>
                <div className="10/12 grid__cell">
                  <div className="field">
                    <input
                      id="restrictGroup"
                      name="restriction"
                      type="checkbox"
                      value="group"
                      onChange={handleChangeRestriction}
                      checked={restriction === 'group'}
                    />
                    <i className="checkPseudo" />
                    <label htmlFor="restrictGroup">
                      <span>Group</span>
                    </label>
                  </div>

                  <div className="field">
                    <input
                      id="restrictIndividual"
                      name="restriction"
                      type="checkbox"
                      value="individual"
                      onChange={handleChangeRestriction}
                      checked={restriction === 'individual'}
                    />
                    <i className="checkPseudo" />
                    <label htmlFor="restrictIndividual">
                      <span>Individual</span>
                    </label>
                  </div>
                </div>
              </>
            ) : null}
          </div>
          <button type="submit" id="addRule">
            Add
          </button>
        </div>
      </form>
    </div>
  );
});
