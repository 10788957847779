import ConfigSchema from './config-schema';
import Ajv from 'ajv';

const ajv = new Ajv({ strict: false });
const validate = ajv.compile(ConfigSchema);

export function validateConfig(json) {
  let config;
  try {
    config = JSON.parse(json);
  } catch (e) {
    throw new Error('Config should be a valid JSON array.');
  }

  const valid = validate(config, ConfigSchema);
  if (!valid) {
    const readableErrors = validate.errors.map(error => error.message).join(', ');
    throw new Error(`Invalid configuration: ${readableErrors}`);
  }

  if (config.useAutoZones === true) {
    config.proximity = true;
  }

  if (config.proximity === false) {
    config.useAutoZones = false;
  }

  const seenIds = [];

  config.rules.forEach(rule => {
    seenIds.push(rule.id);
  });

  return { config, maxId: seenIds.length ? Math.max(...seenIds) : 1 };
}
