export function createRule(id, startAge, endAge, gender, restriction) {
  const rule = {
    id: String(id),
    trigger_group: {},
  };

  if (gender === 'nobody') {
    rule.trigger_group = {
      type: 'UNTARGETED',
      operator: 'IS',
      value: true,
    };
    return rule;
  }

  rule.trigger_group = {
    operator: 'AND',
    children: [],
  };

  if (gender === 'female' || gender === 'male') {
    rule.trigger_group.children.push({
      type: 'GENDER',
      operator: 'IS',
      value: gender.toUpperCase(),
    });
  }

  if (restriction) {
    rule.trigger_group.children.push({
      type: 'PERSONS',
      operator: restriction === 'individual' ? 'IS' : 'IS_MORE_THAN',
      value: '1',
    });
  }

  if (startAge !== null && startAge !== undefined && endAge !== null && endAge !== undefined) {
    rule.trigger_group.children.push({
      type: 'AGE',
      operator: 'IS_BETWEEN',
      value: `${startAge},${endAge}`,
    });
  }

  return rule;
}
