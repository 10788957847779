import React, { memo, useState, useEffect } from 'react';
import { formatRule } from '../util/format-rule';

const DeleteButton = ({ deleteRule, rule }) => {
  if (deleteRule !== undefined) {
    const handleClick = e => {
      e.preventDefault();
      deleteRule(rule.id);
    };

    return (
      /* eslint-disable-next-line */
      <a href="#" className="delete" onClick={handleClick}>
        -
      </a>
    );
  }
  return null;
};

const VisualRules = ({ config, deleteRule }) => (
  <div className="rules">
    {config.rules.map(rule => (
      <div className="rule" key={rule.id}>
        <div className="grid grid--middle">
          <div className="grid__cell">
            <div className="count name">Rule {rule.id}.</div>
            <h2>{formatRule(rule)}</h2>
            <DeleteButton rule={rule} deleteRule={deleteRule} />
          </div>
        </div>
      </div>
    ))}
  </div>
);

const JSONRules = ({ viewMode, config, setConfig }) => {
  let textArea;

  const [jsonConfig, setJsonConfig] = useState(JSON.stringify(config, null, 2));
  const [editMode, setEditMode] = useState(false);
  const handleChangeJson = e => setJsonConfig(e.target.value);
  const handleFocusOutConfig = () => setConfig(jsonConfig);
  const handleClickPre = () => setEditMode(true);

  useEffect(() => {
    setJsonConfig(JSON.stringify(config, null, 2));
    setEditMode(false);
  }, [config]);

  useEffect(() => {
    if (viewMode === 'right' && textArea) {
      textArea.focus();
    }
  });

  return (
    <div className="rules dark">
      {editMode && setConfig !== undefined ? (
        <textarea
          value={jsonConfig}
          ref={input => (textArea = input)}
          onChange={handleChangeJson}
          onBlur={handleFocusOutConfig}
        />
      ) : (
        <pre onClick={handleClickPre}>{jsonConfig}</pre>
      )}
    </div>
  );
};

export const Rules = memo(({ viewMode, config, deleteRule, setConfig }) =>
  viewMode === 'left' ? (
    <VisualRules config={config} deleteRule={deleteRule} />
  ) : (
    <JSONRules viewMode={viewMode} config={config} setConfig={setConfig} />
  )
);
